<template>
  <div :class="[`app-badge app-badge--${type}`, { '!rounded-full': rounded }]">
    <span>{{ $t(label) }}</span>
  </div>
</template>

<script>
export default {
  name: 'Badge',
  props: {
    label: {
      type: String,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    // primary, secondary
    type: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
